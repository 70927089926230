import React from 'react';
import { Link } from 'gatsby';
import { theme } from '../styles/theme';
import { css } from '@emotion/react';

import logo from '../images/logos/logo-blood-components-white.svg';
import logoNhs from '../images/logos/logo-nhs@2x.png';
import CustomNavTitle from './CustomNavTitle';

const Header = ({
  isTermsPage = false,
  navTitle,
  showInfoPanel,
  navBackDestination = '/',
}) => (
  <header
    css={{
      background: isTermsPage ? theme.colours.white : theme.colours.red,
      position: 'sticky',
      top: 0,
      zIndex: 1,
    }}>
    <div
      css={[
        theme.components.row,
        {
          display: 'flex',
          justifyContent: isTermsPage ? 'flex-end' : 'flex-start',
          alignItems: 'center',
        },
      ]}>
      {isTermsPage ? (
        <img src={logoNhs} alt="NHS" width="145.22" height="58.54" />
      ) : (
        <Link to="/" title="Home">
          <img
            src={logo}
            alt="NHS Blood Components"
            css={{
              maxWidth: 142,
            }}
          />
        </Link>
      )}
    </div>
    {navTitle && (
      <CustomNavTitle
        destination={navBackDestination}
        showInfo={showInfoPanel}
        title={navTitle}
      />
    )}
    {isTermsPage && (
      <div css={styles.termsIntroContainer}>
        <div css={[theme.components.row, styles.termsIntro]}>
          <img
            src={logo}
            alt="NHS Blood Components"
            width="142"
            height="70.11"
            css={styles.logo}
          />
          <h1 css={styles.nhsTitle}>
            A collaboration between NHS Blood and Transplant and the National
            Blood Transfusion Committee
          </h1>
        </div>
      </div>
    )}
  </header>
);

const styles = {
  termsIntroContainer: css({
    backgroundColor: theme.colours.red,
  }),
  termsIntro: css({
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: theme.gapDouble,
    paddingTop: theme.gapDouble,
  }),
  logo: css({
    marginRight: theme.gapDouble,
    marginBottom: 5,
  }),
  nhsTitle: css({
    maxWidth: '46ch',
    fontSize: '1.65rem',
    color: theme.colours.white,
  }),
};

export default Header;
