import React, { useState } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';

import { theme } from '../styles/theme';

const CustomNavTitle = ({ title, destination, showInfo = false }) => {
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <div css={styles.wrap}>
      <div css={[theme.components.row, styles.nav]}>
        <span css={styles.linkTitle}>
          <Link css={styles.button} to={destination} title="Go back">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.214"
              height="17.14"
              viewBox="0 0 10.214 17.14">
              <path
                d="M70.417,43.491c-.027-.027-.058-.046-.086-.071l-6.856-6.856a1.636,1.636,0,0,0-2.314,0h0a1.636,1.636,0,0,0,0,2.314l5.777,5.777-5.777,5.777a1.636,1.636,0,0,0,0,2.314h0a1.636,1.636,0,0,0,2.314,0l6.856-6.856c.028-.025.059-.044.086-.071a1.657,1.657,0,0,0,0-2.33Z"
                transform="translate(70.896 53.226) rotate(180)"
                fill={theme.colours.red}
              />
            </svg>
          </Link>
          <h1 css={[theme.components.title, theme.components.titleLarge]}>
            {title}
          </h1>
        </span>

        {showInfo && (
          <button
            css={styles.infoButton}
            onClick={() => setInfoOpen(!infoOpen)}
            aria-label="Information">
            ?
          </button>
        )}

        {infoOpen && (
          <div css={styles.modalBackdrop}>
            <article css={styles.infoPanel}>
              <p>
                These summaries should be used in conjunction with relevant BSH
                and other published guidelines
              </p>
              <button
                onClick={() => setInfoOpen(!infoOpen)}
                css={styles.closeButton}>
                <span>Close</span>
              </button>
            </article>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  wrap: css({
    backgroundColor: theme.colours.white,
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.16)',
  }),
  nav: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  linkTitle: css({
    display: 'flex',
    alignItems: 'center',
  }),
  button: css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    marginRight: theme.gapHalf,
    borderRadius: '50%',
    '&:hover, &:focus': {
      backgroundColor: '#e0e0e0',
    },
  }),
  infoButton: css({
    appearance: 'none',
    cursor: 'pointer',
    border: 'none',
    display: 'inline-flex',
    width: '32px',
    height: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    color: theme.colours.red,
    backgroundColor: theme.colours.lightRed,
    fontWeight: 'bold',
    marginRight: theme.gapHalf,
    '&:hover, &:focus': {
      boxShadow: `0 0 0 2px ${theme.colours.red}`,
    },
  }),
  modalBackdrop: css({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(255, 255, 255, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  }),
  infoPanel: css({
    margin: '2rem',
    maxWidth: '380px',
    padding: theme.gapDouble,
    background: theme.colours.white,
    border: `2px solid ${theme.colours.red}`,
    fontWeight: 'bold',
  }),
  closeButton: css({
    appearance: 'none',
    background: 'none',
    cursor: 'pointer',
    border: 'none',
    display: 'flex',
    color: theme.colours.black,
    fontWeight: 'bold',
    marginTop: theme.gapHalf,
    opacity: 0,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 3,
    span: {
      opacity: 0,
    },
  }),
};

export default CustomNavTitle;
