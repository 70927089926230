import { css } from '@emotion/react';

const colours = {
  red: '#DA291C',
  lightRed: '#FBE8E5',
  blue: '#0072C6',
  yellow: '#ED8B00',
  purple: '#A93172',
  green: '#009639',
  lightGrey: '#EFEFEF',
  midGrey: '#DFDFDF',
  darkGrey: '#425563',
  black: '#333333',
  white: '#FFFFFF',
};
const borderRadius = '8px';
const gapDouble = '2rem';
const gap = '1rem';
const gapHalf = '0.5rem';
const fzH1 = '1.875rem';
const fzH2 = '1.5rem';
const fzH3 = '1.375rem';

export const theme = {
  colours,
  borderRadius,
  gapDouble,
  gap,
  gapHalf,
  fzH1,
  fzH2,
  fzH3,
  components: {
    row: css({
      maxWidth: '1460px',
      padding: '1.5rem 1rem',
      margin: '0 auto',
    }),
    rowSmall: css({
      maxWidth: '860px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
    title: css({
      color: colours.black,
      fontWeight: 'bold',
      fontSize: '1.5rem',
    }),
    titleSmall: css({
      fontSize: '1.375rem',
    }),
    titleLarge: css({
      fontSize: '1.875rem',
    }),
    themeColour: css({
      color: `var(--theme, ${colours.red})`,
    }),
    button: css({
      display: 'inline-block',
      padding: `${gap} ${gapDouble}`,
      borderRadius: borderRadius,
      fontWeight: 'bold',
      textDecoration: 'none',
      textAlign: 'center',
      backgroundColor: colours.red,
      color: colours.white,
      '&:hover, &:focus': {
        backgroundColor: '#b91600',
      },
    }),
    buttonGrey: css({
      backgroundColor: colours.lightGrey,
      color: colours.red,
      '&:hover, &:focus': {
        backgroundColor: '#e0e0e0',
      },
    }),
  },
};
