import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import firebase from 'gatsby-plugin-firebase';

import Header from './header';
import '../styles/layout.css';

const Layout = ({
  children,
  isTermsPage = false,
  navTitle,
  showInfoPanel = true,
}) => {
  useEffect(() => {
    function checkTermsAccepted() {
      const termsAcceptedResult = localStorage.getItem('terms_accepted');

      if (!(termsAcceptedResult && termsAcceptedResult === 'true')) {
        navigate('/terms');
      } else {
        firebase.analytics();
      }
    }
    checkTermsAccepted();
  }, []);

  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    if (pathname !== '/terms' && firebase) {
      firebase.analytics().setCurrentScreen(pathname);
      firebase.analytics().logEvent('page_view', {
        path: pathname,
      });
      firebase.analytics().logEvent('screen_view', {
        screen_name: pathname,
      });
    }
  }, [location]);

  return (
    <>
      <Header
        isTermsPage={isTermsPage}
        navTitle={navTitle}
        showInfoPanel={showInfoPanel}
      />
      <main>{children}</main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isTermsPage: PropTypes.bool,
};

export default Layout;
